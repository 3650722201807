<template>
  <div class="products">
    <article class="product-wrapper" v-for="product in products" :key="product._id">
      <FormCheckbox />
      <router-link class="product" :to="`/products/${product._id}`">
        <img class="image" :src="product?.images?.[0]" :alt="product.name" />
        <div class="name">
          {{ product.name }}<br />
          <small class="category">{{ product.category }}</small>
        </div>
        <div class="status" :class="statusColor(product.status)">{{ product.status }}</div>
        <div class="quantity">
          {{ getTotalQty(product.variants) }}
          <br />
          <span v-if="product.variants.length > 1">
            <small>{{ product.variants.length }} variants</small>
          </span>
        </div>
        <div class="type">{{ product.type }}</div>
        <div class="vendor">{{ product.seller_name || $t('ui.in_house') }}</div>
      </router-link>
      <div class="action" @click="confirmDelete(product)">
        <IconLibrary name="delete" color="red" size="xs" :hasMargin="false" />
      </div>
    </article>
  </div>

  <!-- Modal de confirmation -->
  <Modal ref="deleteModal" size="small">
    <template #title>
      <h4>{{ $t('ui.confirm_deletion') || 'Confirm Deletion' }}</h4>
    </template>

    <div class="delete-confirmation">
      <img
        v-if="productToDelete?.images?.[0]"
        class="product-thumbnail"
        :src="productToDelete.images[0]"
        :alt="productToDelete?.name"
      />
      <div class="delete-info">
        <p>
          {{
            $t('ui.delete_product_warning') ||
            'This action cannot be undone. Are you sure you want to delete this product?'
          }}
        </p>
        <p v-if="productToDelete">
          <strong>{{ productToDelete.name }}</strong>
        </p>
      </div>
    </div>

    <template #footer>
      <div class="modal-actions">
        <button class="btn btn-secondary" @click="cancelDelete">
          {{ $t('ui.cancel') || 'Cancel' }}
        </button>
        <button class="btn btn-danger" @click="proceedWithDelete">
          {{ $t('ui.delete') || 'Delete' }}
        </button>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import FormCheckbox from '../Shared/Forms/FormCheckbox.vue';
import IconLibrary from '../Shared/IconLibrary.vue';
import Modal from '../Shared/Modal.vue';
import { kyWithAuth } from '../../libs/ky';
import { useProductStore } from '../../stores/products.js';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';

const productStore = useProductStore();
const deleteModal = ref(null);
const productToDelete = ref(null);

const { getSortedProducts } = storeToRefs(productStore);
const products = getSortedProducts;

const getTotalQty = (variants) => {
  return variants.reduce((total, variant) => total + variant.quantity, 0);
};

const statusColor = (status) => (status === 'active' ? 'green' : 'yellow');

function confirmDelete(product) {
  productToDelete.value = product;
  deleteModal.value.open();
}

function cancelDelete() {
  productToDelete.value = null;
  deleteModal.value.close();
}

async function proceedWithDelete() {
  if (!productToDelete.value) return;

  try {
    await deleteProduct(productToDelete.value._id, productToDelete.value.category);
    deleteModal.value.close();
    productToDelete.value = null;
  } catch (error) {
    console.error(error);
  }
}

async function deleteProduct(productId, productCategory) {
  try {
    await productStore.deleteProduct(productId);
    const { products: productsWithCategory } = await kyWithAuth
      .get(`products/category/${productCategory}`)
      .json();
    if (!productsWithCategory.length)
      await kyWithAuth.delete(`categories/${productCategory}`).json();
  } catch (error) {
    console.error(error);
  }
}
</script>

<script>
export default {
  props: {
    products: {
      type: Array,
      required: true,
      default: []
    },
    type: {
      type: String,
      default: 'list'
    }
  }
};
</script>

<style lang="scss" scoped>
article.product-wrapper {
  display: grid;
  grid-template-columns: 35px 1fr 35px;
  grid-template-rows: 1fr;
  grid-template-areas: 'checkbox product action';
  align-items: center;
  gap: 0px 20px;
  width: 100%;
  padding: 10px 15px;
  background-color: $white;
  transition: background-color $base-speed-fast ease;
  a {
    display: grid;
    grid-template-columns: 50px 1fr 70px 60px 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 20px;
    grid-template-areas: 'image name status quantity type vendor';
    color: $brand-deep-purple;
    align-items: center;
    text-decoration: none;
    width: 100%;
  }
}

article .status {
  padding: 3px 14px;
  border-radius: 100px;
  font-size: 85%;
  display: inline-flex;
  align-items: center;
  text-transform: capitalize;
  background-color: $brand-light-gold;
  &.green {
    background-color: $brand-light-mint;
  }
}

article.product-wrapper:nth-of-type(even) {
  border-top: 1px dashed rgba($brand-light-purple, 0.75);
  border-bottom: 1px dashed rgba($brand-light-purple, 0.75);
}
article.product-wrapper:last-child {
  border-bottom: none;
}
article.product-wrapper:hover {
  cursor: pointer;
  background-color: rgba($brand-mint, 0.05);
}
article.product-wrapper.router-link-active {
  background-color: rgba($brand-light-purple, 0.4);
  color: $white;
}

.category {
  font-size: 80%;
  color: $brand-light-purple;
}

// Grid Area
.form-checkbox {
  grid-area: checkbox;
  text-align: center;
}
.product {
  grid-area: product;
}
.image {
  grid-area: image;
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: $base-radius;
  overflow: hidden;
  border: 1px solid rgba($brand-blue, 0.1);
  padding: 2px;
}

.quantity small {
  font-size: 80%;
  font-style: italic;
  color: rgba($brand-light-purple, 0.85);
}
.name {
  grid-area: name;
}
.status {
  grid-area: status;
}
.quantity {
  grid-area: quantity;
}
.type {
  grid-area: type;
}
.vendor {
  grid-area: vendor;
}
.action {
  grid-area: action;
}

@media (max-width: 767px) {
  article.product-wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'product';
    @include padding(xs);
    a {
      grid-template-columns: 50px 0.7fr 0.3fr;
      grid-template-rows: 1fr 1fr;
      gap: 0px 20px;
      font-size: 0.85rem;
      grid-template-areas:
        'image name quantity'
        'image status quantity';
    }
  }
  .quantity {
    justify-self: flex-end;
    text-align: right;
    small {
      font-size: 90%;
    }
  }
  .status {
    justify-self: flex-start;
  }

  .action,
  .type,
  .vendor,
  .form-checkbox {
    display: none;
  }
}

.modal-actions {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  width: 100%;
}

.btn {
  padding: 0.5rem 1rem;
  border-radius: $base-radius;
  border: none;
  cursor: pointer;
  font-weight: $font-weight-bold;
  transition: background-color 150ms ease;
}

.btn-secondary {
  background-color: $brand-light-indigo;
  color: $brand-deep-purple;

  &:hover {
    background-color: darken($brand-light-indigo, 10%);
  }
}

.btn-danger {
  background-color: #f44336;
  color: white;

  &:hover {
    background-color: darken(#f44336, 10%);
  }
}

.delete-confirmation {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.product-thumbnail {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: $base-radius;
  border: 1px solid rgba($brand-blue, 0.1);
  padding: 2px;
}

.delete-info {
  flex: 1;
}
</style>
