<template>
  <!-- <section class="hero">
    <img src="/img/splash-image.jpg" alt="" />
  </section> -->
  <section v-if="collectionStore.collections.length" class="collections-container">
    <h4>{{ $t('ui.shop_by') }}</h4>
    <h3>{{ $t('ui.collections') }}</h3>
    <div class="collections">
      <router-link
        v-for="collection in collectionStore.collections"
        :key="collection._id"
        :to="`/shop/collections/${collection._id}`"
      >
        <img class="cover" :src="collection.cover" :alt="collection.title" />
        <h6>{{ collection.title }}</h6>
      </router-link>
    </div>
  </section>
  <section class="products-container" v-if="products.length">
    <!-- <h4>{{ $t('ui.our_products') }}</h4> -->
    <h3>{{ $t('ui.our_products') }}</h3>
    <div class="products">
      <router-link
        v-for="product in products"
        :key="product._id"
        :to="`/shop/products/${product._id}`"
      >
        <img :src="product.images?.[0]" :alt="product.name" />
        <div class="content-wrapper">
          <p>
            <small>{{ product.vendor || product.seller_name || 'In-House' }}</small>
          </p>
          <h6>{{ product.name }}</h6>
        </div>
        <MoneyFormat :amount="product.variants[0].price" currency="EUR" />
      </router-link>
    </div>
    <Pagination
      :currentPage="productStore.currentPage"
      :hasNextPage="productStore.hasNextPage"
      :pageSize="productStore.pageSize"
      @update:page="changePage"
      v-if="products.length"
    />
  </section>
</template>

<script setup>
import { onMounted } from 'vue';
import { useProductStore } from '../stores/products.js';
import { useCollectionStore } from '../stores/collections.js';
import { usePersistCart } from '../composables/usePersistCart';
import { storeToRefs } from 'pinia';
import MoneyFormat from '../components/Shared/MoneyFormat.vue';
import Pagination from '../components/Shared/Pagination.vue';

const productStore = useProductStore();
const collectionStore = useCollectionStore();

const { getSortedProducts } = storeToRefs(productStore);
const products = getSortedProducts;
usePersistCart();

function changePage(page) {
  productStore.currentPage = page;
  productStore.fetchProducts({ page, pageSize: productStore.pageSize });
}

onMounted(async () => {
  try {
    await productStore.fetchProducts({ shop: true });
    await collectionStore.fetchCollections();
  } catch (e) {
    console.error(e);
  }
});
</script>

<style lang="scss" scoped>
h4 {
  color: $brand-blue;
  font-size: 1.15rem;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0;
}
h3 {
  text-align: center;
  font-weight: $font-weight-bold;
  margin: 0.25rem 0 1rem;
  font-size: 2rem;
}
section + section {
  margin-top: 5rem;
}
aside {
  margin-top: 35px;
  padding: 10px !important;
  border-radius: $base-radius;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .tag {
    padding: 2px 8px;
    background-color: coral;
    color: white;
    border-radius: $base-radius;
    font-size: 80%;
    font-weight: $font-weight-bold;
  }
}
header {
  display: flex;
  align-items: center;
  gap: 15px;
}
header > img {
  width: 70px;
}
.hero {
  width: 100%;
  img {
    border-radius: $base-radius;
    width: 100%;
    box-shadow: 0 0 0.5rem rgba($black, 0.1);
  }
}
h6 {
  margin: 0;
  font-size: 95%;
  color: $black;
  margin-top: 0.5rem;
}
.products {
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 330px));
  grid-gap: 1.5rem;
  justify-content: center;
  margin: 50px 0;
}
.products > a,
.products > article {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  transition: transform 150ms ease-in-out, stroke 150ms ease;
  background-color: $white;
  border-radius: $base-radius;
  box-shadow: 0px 0px 8px rgba($black, 0.1);
  padding: 1rem 0.75rem;
  text-align: center;
  &:hover {
    transform: scale(1.05);
    cursor: pointer;
    :deep(svg) {
      stroke: $brand-blue;
    }
  }
  small {
    color: $brand-grey;
  }
  img {
    overflow: hidden;
    border-radius: 5px;
    object-fit: contain;
    aspect-ratio: 1;
    max-height: 200px;
    width: 100%;
  }
  .content-wrapper {
    margin-top: 0.5rem;
    p {
      margin: 0;
      color: $brand-blue;
    }
  }
}
.cover {
  width: 120px;
  height: 120px;
  border-radius: 120px;
  object-fit: cover;
}
.collections {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 2rem;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  a {
    text-align: center;
    padding: 1.25rem 0.75rem;
    background-color: white;
    box-shadow: 0 0 8px rgba(black, 0.1);
    border-radius: $base-radius;
    transition: transform $base-speed-fast ease;
    &:hover {
      transform: scale(1.05);
    }
  }
}
</style>
