<template>
  <div>
    <section v-if="showOptions">
      <FormInput title="forms.label.option" v-model="option" />
      <FormInput title="forms.label.values" @input="handleValue($event.target.value)" />
    </section>

    <section v-if="modelValue?.length > 0" class="existing-options">
      <div v-for="(opt, index) in modelValue" :key="index" class="option-item">
        <div class="option-header">
          <span class="option-name">{{ opt.name }}</span>
          <button class="btn-icon" @click="deleteOption(index)">
            <IconLibrary name="delete" size="xs" />
          </button>
        </div>
        <div class="option-values">
          <div v-for="(value, valueIndex) in opt.values" :key="valueIndex" class="value-badge">
            {{ value }}
            <button class="badge-delete" @click.prevent="deleteValue(index, valueIndex)">
              <IconLibrary name="delete" size="xs" />
            </button>
          </div>
          <button type="button" class="value-badge add-value" @click="openAddValueModal(index)">
            <IconLibrary name="add" size="xs" /> Ajouter une valeur
          </button>
        </div>
      </div>
    </section>

    <nav class="flex">
      <div class="btn btn-dashed" @click="showOptions = !showOptions">
        {{
          showOptions
            ? $t('forms.button.cancel_variant_option')
            : $t('forms.button.add_variant_option')
        }}
      </div>
      <div class="btn" v-if="showOptions && optionValues.length > 0" @click="saveOption">
        Ajouter l'option
      </div>
    </nav>

    <!-- Modal pour ajouter une nouvelle valeur -->
    <Modal ref="modal" size="small">
      <template #title>Ajouter une nouvelle valeur</template>
      <FormInput title="forms.label.value" v-model="newValue" placeholder="Nouvelle valeur" />
      <template #footer>
        <button type="button" class="btn btn-ghost" @click="cancelAddValue">Annuler</button>
        <button type="button" class="btn" @click="addNewValue">Ajouter</button>
      </template>
    </Modal>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import FormInput from '../Shared/Forms/FormInput.vue';
import IconLibrary from '../Shared/IconLibrary.vue';
import Modal from '../Shared/Modal.vue';

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => []
  }
});

const emit = defineEmits(['update:modelValue', 'update:options']);

const showOptions = ref(false);
const option = ref('');
const optionValues = ref([]);
const newValue = ref('');
const currentOptionIndex = ref(null);
const modal = ref(null);

const handleValue = (optionValue) => {
  optionValues.value = optionValue.split(',').map((option) => option.trim());
};

const deleteOption = (index) => {
  const newOptions = [...props.modelValue];
  newOptions.splice(index, 1);
  emit('update:modelValue', newOptions);
  emit('update:options', newOptions);
};

const deleteValue = (optionIndex, valueIndex) => {
  const newOptions = [...props.modelValue];
  newOptions[optionIndex].values.splice(valueIndex, 1);
  emit('update:modelValue', newOptions);
  emit('update:options', newOptions);
};

const openAddValueModal = (index) => {
  currentOptionIndex.value = index;
  newValue.value = '';
  modal.value.open();
};

const cancelAddValue = () => {
  modal.value.close();
  newValue.value = '';
  currentOptionIndex.value = null;
};

const addNewValue = () => {
  if (!newValue.value.trim()) return;

  const newOptions = [...props.modelValue];
  newOptions[currentOptionIndex.value].values.push(newValue.value.trim());
  emit('update:modelValue', newOptions);
  emit('update:options', newOptions);
  cancelAddValue();
};

const saveOption = () => {
  const newOption = { name: option.value, values: optionValues.value };
  const newOptions = [...(props.modelValue || []), newOption];

  emit('update:modelValue', newOptions);
  emit('update:options', newOptions);
  resetForm();
};

const resetForm = () => {
  option.value = '';
  optionValues.value = [];
  showOptions.value = false;
  newValue.value = '';
  currentOptionIndex.value = null;
};
</script>

<style lang="scss" scoped>
.existing-options {
  margin-bottom: 20px;
}

.option-item {
  background-color: $brand-light-grey;
  border: 1px solid $brand-light-purple;
  border-radius: $base-radius;
  padding: 12px;
  margin-bottom: 8px;
}

.option-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.option-name {
  font-weight: bold;
}

.option-values {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
}

.value-badge {
  display: inline-flex;
  align-items: center;
  background-color: $brand-light-purple;
  color: $white;
  padding: 4px 8px;
  border-radius: 16px;
  font-size: 0.9em;

  &.add-value {
    background-color: transparent;
    border: 1px dashed $brand-light-purple;
    color: $brand-light-purple;
    cursor: pointer;
    padding: 4px;

    &:hover {
      background-color: rgba($brand-light-purple, 0.1);
    }
  }
}

.badge-delete {
  background: none;
  border: none;
  color: $white;
  padding: 0;
  margin-left: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.7;
  }
}

.btn-icon {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;

  &:hover {
    opacity: 0.7;
  }
}

.add-value-input {
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid rgba($brand-light-purple, 0.3);
}

.add-value-actions {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.flex {
  justify-content: flex-start;
  gap: 25px;
  .btn-dashed {
    flex-grow: 1;
  }
}
</style>
