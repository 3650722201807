export { useCategoryStore };
import { defineStore } from 'pinia';
import { kyWithAuth } from '../libs/ky';

const useCategoryStore = defineStore('categories', {
  state: () => {
    return {
      categories: [],
      loading: false,
      lastFetched: null
    };
  },
  getters: {
    shouldRefetch() {
      // Recharger si jamais chargé ou si dernière charge il y a plus de 2 minutes
      if (!this.lastFetched) return true;
      const twoMinutesAgo = new Date(Date.now() - 2 * 60 * 1000);
      return this.lastFetched < twoMinutesAgo;
    }
  },
  actions: {
    async fetchCategories(forceRefresh = false) {
      // Ne pas recharger si déjà en cours de chargement
      if (this.loading) return this.categories;

      // Ne pas recharger si récemment chargé, sauf si forceRefresh est true
      if (!forceRefresh && !this.shouldRefetch) return this.categories;

      this.loading = true;
      try {
        const { categories = [] } = await kyWithAuth.get('categories').json();
        this.categories = categories;
        this.lastFetched = new Date();
        return categories;
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        this.loading = false;
      }
    },
    async fetchCategory(categoryName) {
      const { category } = await kyWithAuth.get(`categories/${categoryName}`).json();
      return category;
    }
  }
});
